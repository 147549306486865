<template>
    <v-container fluid @contextmenu="show" :style="{'background-color':widget.background_color}">
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-menu
            v-model="showMenu"
            :position-x="x"
            :position-y="y"
            absolute
            offset-y
            @click="dialog=true"
            v-if="$store.state.settingMode && edit"
        >
        <v-list :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode">
            <v-list-item @click="showEdit=true">
                <v-list-item-title><strong>{{$store.getters.getTextMap().edit}}</strong></v-list-item-title>
                <v-list-item-icon>
                    <v-icon small>mdi-pencil</v-icon>
                </v-list-item-icon>
            </v-list-item>
            <v-list-item @click="deleteWidget" >
                <v-list-item-title><strong>{{$store.getters.getTextMap().delete}}</strong></v-list-item-title>
                <v-list-item-icon>
                     
                    <v-icon color="red" small>mdi-trash-can</v-icon>

                </v-list-item-icon>
            </v-list-item>
        </v-list>
    </v-menu>
    <v-dialog
      v-model="showEdit"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
        <v-card :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode">
            <v-toolbar :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().foregroundColorCode">
                <v-btn icon :dark="$store.getters.getColorPalette().isDark" @click="showEdit = false"><v-icon>mdi-close</v-icon></v-btn>
                <v-toolbar-title>{{this.widget.label}}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items><v-btn :dark="$store.getters.getColorPalette().isDark" text @click="showEdit = false">{{$store.getters.getTextMap().close}}</v-btn></v-toolbar-items>
            </v-toolbar>
            <AddGaugeWidget :widget="widget" v-on:close="showEdit=false"/>
        </v-card>
    </v-dialog>
    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
        <v-row justify="center" no-gutters>
            <v-col cols="12" align="left" align-self="center">
                <div v-if="widget.meta.data" :style="[widget.height==='fixed' ? {'background-color':$store.getters.getColorPalette().backgroundColor2Code,'height':'175px',}:{}]">
                    <div :color="$store.getters.getColorPalette().accentCode">
                        <!-- <v-card-subtitle> -->

                            <v-skeleton-loader
                            v-if="loading"
                                class="mx-auto"
                                type="text"
                                ></v-skeleton-loader>
                                <span><strong>{{ widget.label }}</strong></span>
                                <!-- <div v-if="val_placement=='top'" :color="$store.getters.getColorPalette().accentCode">{{title}}: {{currentValueComputes}} {{unit}}</div> -->
                                <!-- </v-card-subtitle> -->
                                <SingleParamStreamGauge
                                :value="currentValueComputes"
                                :maxValue="maxValue"
                                :minValue="minValue"
                                :options="options"
                                :title="title"
                                :unit="unit"
                                :width="width"
                                :height="height"
                                :paramLabel="paramLabel"
                                />
                                <!-- <div v-if="val_placement=='bottom'" :color="$store.getters.getColorPalette().accentCode">{{title}}: {{currentValueComputes}} {{unit}}</div> -->
                    </div>
                </div>
                <div v-else :style="[widget.height==='fixed' ? {'height':'175px'}:{}]" >
                    <v-icon small>mdi-hail</v-icon>
                    <h5>{{$store.getters.getTextMap().not_configured}}</h5>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {io} from 'socket.io-client'
import InfoAlert from '@/components/InfoAlert'
// import DeleteConfirmation from '@/components/DeleteConfirmation'
import AddGaugeWidget from '@/components/crud_components/widgets/AddGaugeWidget'
import SingleParamStreamGauge from '@/components/panels/SingleParamStreamGauge'
import axios from 'axios'
export default {
name: "GaugeWidget",
components:{
        AddGaugeWidget,
        SingleParamStreamGauge,
        InfoAlert,
        // DeleteConfirmation
    },
// props:['widget'],
props:{
    widget:{
        type:Object,
        required:true

    },
    val_placement: {
        type: String,
        default: "bottom"
      },
            edit:{
            type:Boolean,
            default:true
      }
},
data() {
    return {
            showDialog:false,
            showEdit:false,
            loading:false,
            x: 0,
            y: 0,
            showMenu:false,
            stream:null,
            val:null,
            info:'',
            showDismissibleAlert:false,
            paramLabel:null
    };
  },
created(){
    //this.socket=io('https://localhost:80');
    this.stream=io(this.$store.state.streamApi,{withCredentials: true, auth:{token:this.$store.state.jwt}})
    },

mounted() {

    if(this.widget && this.widget.meta && this.widget.meta.data){
        this.widget.meta.data.forEach((item)=>{
            this.paramLabel=item.label
        })
    }
    
    // console.log(this.widget.meta.data[0].color_meta,'this.widget.meta.data.color_meta before json parse')
    if(this.widget.meta.data){

        this.stream.on("connect_error", (err) => {
        console.log(err.message); // prints the message associated with the error
        });
          this.stream.on('error',data=>{
          console.log(data)
          
        })
        //   if(this.dashboardGauge.param_type=="parameter"){
        //   if(this.widget.meta.data.param_type=="parameter"){
          
        this.stream.on('parameter',data=>{
          //console.log(data)
          if(data.constructor==String){
            data=JSON.parse(data)
        }
          this.val=data.data
          //this.streamData.push(data)
        })
        this.stream.emit('getParam',this.widget.meta.data[0].params)
        //   }
        // this.initializeGauge();
    }
  },
beforeDestroy() {
    this.stream.close()
    delete this.gauge;
  },
computed:{
    width(){
        if(this.widget.height==='fixed'){
            return '200'
        }
        return null
    },
    height(){
        if(this.widget.height==='fixed'){
            return '150px'
        }
        return null
    },
    currentValueComputes(){
    // if(this.dashboardGauge.param_type=="parameter"){
    // if(this.widget.meta.data.param_type=="parameter"){
        if(this.widget.meta.data){
            if(this.val && !isNaN(this.val)){
                return Math.round(Number(this.val)*100)/100
            }else{
                // let op=this.$store.getters.getCurrentParameterDataById(this.dashboardGauge.param)
            //   console.log(this.widget.meta.data[0].params,'this.widget.meta.data[0].params')
                let op=this.$store.getters.getCurrentParameterDataById(this.widget.meta.data[0].params)
            if(op && op.length>0){
                return Math.round(Number(op[op.length-1]['data'])*100)/100
            }
            }
        }
        return null
    },
    maxValue(){
        // if(this.dashboardGauge.color_meta && JSON.parse(this.dashboardGauge.color_meta).length >0){
        if(this.widget.meta.data){
            if(this.widget.meta.data[0].color_meta && this.widget.meta.data[0].color_meta.length >0){
                let max=0
                // for(let i of JSON.parse(this.dashboardGauge.color_meta)){
                for(let i of this.widget.meta.data[0].color_meta){
                    if(i.max>max){
                        max=Number(i.max)
                    }
                }
                return max
            // }else if(this.dashboardGauge.max_val && Number(this.dashboardGauge.max_val)){
            // }else if(this.dashboardGauge.max_val && Number(this.dashboardGauge.max_val)){
            //     return Number(this.dashboardGauge.max_val)
            }else{
                let c=Number(this.currentValueComputes)
                if(c){
                    return c+(c*0.2)
                }else{
                    return 1
                }
            }
        }
        return null
    },
    minValue(){
        // if(this.dashboardGauge.color_meta && JSON.parse(this.dashboardGauge.color_meta).length >0){
        if(this.widget.meta.data){
            if(this.widget.meta.data[0].color_meta && this.widget.meta.data[0].color_meta.length >0){
                let min=0
                // for(let i of JSON.parse(this.dashboardGauge.color_meta)){
                for(let i of this.widget.meta.data[0].color_meta){
                    if(i.min<min){
                        min=Number(i.min)
                    }
                }
                return min
            // }else if(this.dashboardGauge.min_val && Number(this.dashboardGauge.min_val)){
            //     return Number(this.dashboardGauge.min_val)
            }else{
                let c=Number(this.currentValue)
                if(c){
                    return c-(c*0.2)
                }else{
                    return 0
                }
            }
        }
        return null
    },
    title(){
    // return this.dashboardGauge?.name
        if(this.widget.meta.data){
            return this.widget.meta.data[0]?.label
        }
        return null
    },
    unit(){
        // if(this.dashboardGauge.param_type=='parameter'){
        // if(this.widget.meta.data.param_type=='parameter'){
            // let p=this.$store.getters.getParameterById(this.dashboardGauge.param)
            if(this.widget.meta.data){
                let p=this.$store.getters.getParameterById(this.widget.meta.data[0].params)
                if(p && p.unit){
                    return p.unit
                }
            // }
        }
        return null
    },
    options(){
        let op={
            //TODO - remove till StaticZones
        // angle: 0, // The span of the gauge arc
        // lineWidth: 0.1, // The line thickness
        // radiusScale: 1, // Relative radius
        // pointer: {
        // length: 0.49, // // Relative to gauge radius
        // strokeWidth: 0.010, // The thickness
        // color: this.$store.getters.getColorPalette().accentCode // Fill color
        // },
        // limitMax: false,     // If false, max value increases automatically if value > maxValue
        // limitMin: false,     // If true, the min value of the gauge will be fixed
        // //colorStart: '#6FADCF',   // Colors
        // //colorStop: '#8FC0DA',    // just experiment with them
        // strokeColor: '#E0E0E0',  // to see which ones work best for you
        // generateGradient: true,
        // highDpiSupport: true,  // High resolution support
        // percentColors : [[0.0, "#a9d70b" ], [0.50, "#f9c802"], [1.0, "#ff0000"]],
        }
        // console.log(this.dashboardGauge,'this.dashboardGauge')
    //   console.log(this.widget.meta.data[0].color_meta,'this.widget.meta.data.color_meta before json parse')
        // if(this.dashboardGauge.color_meta && JSON.parse(this.dashboardGauge.color_meta).length>0){
        if(this.widget.meta.data){
            if(this.widget.meta.data[0].color_meta && this.widget.meta.data[0].color_meta.length>0){
            // op['staticZones']=JSON.parse(this.dashboardGauge.color_meta)
            op['staticZones']=this.widget.meta.data[0].color_meta
        }
        op['pointer_color']=this.widget.meta.data[0].pointer_color
        }
        return op
},
        },
  methods: {
      show (e) {
          e.preventDefault()
          this.showMenu = false
          this.x = e.clientX
          this.y = e.clientY
          if(this.$store.state.settingMode){
          this.$nextTick(() => {
          this.showMenu = true
        })
        }
        },

    deleteWidget(){
    axios.post(this.$store.state.api+'deleteWidget',this.widget,{headers: {
        Authorization: 'Bearer '+ this.$store.state.jwt
        }}).then(response=>{
        if(response.data.status==='success'){
            // this.$store.dispatch("refreshDashboardGauges")
            //this.onReset()
            this.dialog=false
                this.loading=false
                //this.dialog=false
                //this.$emit('close')
        }
        })
        .catch(err=>{console.log(err)});
    },
    
  cancel(){
          this.showDialog=false
        },
        confirm(){
          this.showDialog=false
        }
  }
};
</script>

<style scoped>
.gauge-title span {
  display: inline;
  text-align: center;
}
</style>