<template>
    <v-container :dark="$store.getters.getColorPalette().isDark" fluid >
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-row >
            <v-col  cols="3" :style="{'border-right': '1px solid '+ $store.getters.getColorPalette().accentCode}">
                <!-- <v-card flat outlined class="widget-card-container pa-3" :dark="$store.getters.getColorPalette().isDark" :style="{'border': '1px solid '+ $store.getters.getColorPalette().accentCode, 'background-color':$store.getters.getColorPalette().backgroundColorCode}"> -->
                    <!-- <v-subheader>Widget Details</v-subheader> -->
                    <v-form ref="paramForm" v-model="valid">
                        <v-row v-if="showTemplate===false">
                            <v-col>
                                <!-- <v-row>
                                    <v-col>
                                        <v-text-field
                                            :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                                            :dark="$store.getters.getColorPalette().isDark" 
                                            :loading="loading"
                                            v-model="form.label"
                                            :counter="25"
                                            :rules="nameRules"
                                            dense
                                            outlined
                                            label="Label"
                                            required
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-select
                                            :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                                            :dark="$store.getters.getColorPalette().isDark" 
                                            v-if="!loading"
                                            v-model="form.width"
                                            :items="widthOptions"
                                            label="Widget Width"
                                            dense
                                            outlined
                                            item-text="label"
                                            item-value="value"
                                            ></v-select>
                                    </v-col>
                                    <v-col>
                                        <v-select
                                            :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                                            :dark="$store.getters.getColorPalette().isDark" 
                                            v-if="!loading"
                                            v-model="form.height"
                                            :items="heightOptions"
                                            label="Widget Height"
                                            dense
                                            outlined
                                            item-text="label"
                                            item-value="value"
                                            ></v-select>
                                    </v-col>
                                </v-row> -->
                                <!-- <v-divider></v-divider> -->
                                <v-row no-gutters>
                                    <v-col>
                                        <v-checkbox :dark="$store.getters.getColorPalette().isDark" v-model="showTemplate" :label="$store.getters.getTextMap().choose_from_templates" ></v-checkbox>
                                    </v-col>
                                </v-row>
                                <v-divider></v-divider>
                                <v-card flat outlined class="widgetype-card-container pa-3" :dark="$store.getters.getColorPalette().isDark" :style="{'border': '0px solid '+ $store.getters.getColorPalette().accentCode, 'background-color':$store.getters.getColorPalette().backgroundColorCode}">
                                    <!-- <v-subheader>Widget Types</v-subheader> -->
                                    <v-row>
                                        <v-col>
                                                <v-list :color="$store.getters.getColorPalette().backgroundColorCode" dense>
                                                    <template  v-for="(item) in widgetTypeOptions">
                                                        <v-list-item  v-if="item.label && item.condition" :key="item.label" @click="handleItemClick(item)">
                                                            <v-list-item-action  v-if="item.condition">
                                                                <v-icon :style="{color:$store.getters.getColorPalette().accentCode}">{{item.icon}}</v-icon>
                                                            </v-list-item-action>
                                                            <v-list-item-content  v-if="item.condition">
                                                                <v-list-item-title>{{ item.label }}</v-list-item-title>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </template>
                                                </v-list>
                                        </v-col>
                                    </v-row>
                                </v-card>
                                <v-row>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row v-else>
                            <v-col>
                                <v-row no-gutters>
                                        <v-col>
                                            <v-checkbox :dark="$store.getters.getColorPalette().isDark" v-model="showTemplate" label="Choose from Templates" ></v-checkbox>
                                        </v-col>
                                    </v-row>
                                <v-row>
                                    <v-col >
                                        <v-select
                                            :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                                            :dark="$store.getters.getColorPalette().isDark" 
                                            v-if="!loading"
                                            v-model="template"
                                            :items="templateOptions"
                                            :label="$store.getters.getTextMap().templates"
                                            dense
                                            outlined
                                            item-text="label"
                                            item-value="value"
                                            required
                                        ></v-select>
                                        <v-skeleton-loader
                                            :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                                            :dark="$store.getters.getColorPalette().isDark" 
                                            v-if="loading"
                                            class="mx-auto"
                                            type="text"
                                            ></v-skeleton-loader>
                                    </v-col>
                                </v-row>
                                <v-row >
                                    <v-col>
                                        <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                                        <v-btn v-if="!loading"  :color="$store.getters.getColorPalette().submitbtnColor" outlined @click="onSubmit">
                                         {{$store.getters.getTextMap().submit}}
                                        </v-btn>
                                    </v-col>
                                    <v-col>
                                        <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                                        <v-btn v-if="!loading" :color="$store.getters.getColorPalette().cancelbtnColor" :dark="$store.getters.getColorPalette().isDark" @click="close">
                                            {{$store.getters.getTextMap().cancel}}
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-col>
                    </v-row>
                </v-form>
                
            <!-- </v-card> -->
        </v-col>
        
        
        <v-col v-if="showTemplate===false && widget" cols="9" class=" widget-card-container">
                    <!-- <v-card  flat outlined class=" widget-card-container pa-3" :dark="$store.getters.getColorPalette().isDark" :style="{'border': '1px solid '+ $store.getters.getColorPalette().accentCode, 'background-color':$store.getters.getColorPalette().backgroundColorCode}"> -->
                        <component v-if="showCrud" :is="getWidget()" :widget="widget" :seq="seq" :disableTimeframe="false" v-on:close="close" v-on:success="emitSuccess()" v-on:update="emitSuccess()" />
                    <!-- </v-card> -->
                </v-col>
            </v-row>
        <!-- <v-form ref="paramForm" v-model="valid">
            <v-row v-if="showTemplate===false">
                <v-col>
                    <v-text-field
                             :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                          :dark="$store.getters.getColorPalette().isDark" 
                            :loading="loading"
                            v-model="form.label"
                            :counter="25"
                            :rules="nameRules"
                            label="Label"
                            required
                    ></v-text-field>
                </v-col>
                <v-col>
                    <v-select
                  :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                            :dark="$store.getters.getColorPalette().isDark" 
                            v-if="!loading"
                            v-model="form.widget_type"
                            :items="widgetTypeOptions"
                            label="WidgetType"
                            dense
                            filled
                            item-text="label"
                            item-value="value"
                            required
                            :rules="objectRules"
                        ></v-select>
                </v-col>
                <v-col>
                    <v-select
                     :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                           :dark="$store.getters.getColorPalette().isDark" 
                            v-if="!loading"
                            v-model="form.width"
                            :items="widthOptions"
                            label="Widget Width"
                            dense
                            filled
                            item-text="label"
                            item-value="value"
                        ></v-select>
                </v-col>
                <v-col>
                    <v-select
                :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                         :dark="$store.getters.getColorPalette().isDark" 
                            v-if="!loading"
                            v-model="form.height"
                            :items="heightOptions"
                            label="Widget Height"
                            dense
                            filled
                            item-text="label"
                            item-value="value"
                        ></v-select>
                </v-col>
            </v-row >
            <v-row v-else>
                <v-col >
                    <v-select
                  :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                          :dark="$store.getters.getColorPalette().isDark" 
                            v-if="!loading"
                            v-model="template"
                            :items="templateOptions"
                            label="Templates"
                            dense
                            filled
                            item-text="label"
                            item-value="value"
                            required
                    ></v-select>
                    <v-skeleton-loader
                    :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                           :dark="$store.getters.getColorPalette().isDark" 
                            v-if="loading"
                            class="mx-auto"
                            type="text"
                        ></v-skeleton-loader>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-checkbox :dark="$store.getters.getColorPalette().isDark" v-model="showTemplate" label="Choose from Templates" ></v-checkbox>
                </v-col>
            </v-row>
            <v-row >
                <v-spacer></v-spacer>
                <v-col cols="2" align="right">
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    <v-btn v-if="!loading"  :color="$store.getters.getColorPalette().submitbtnColor" outlined @click="onSubmit">
                        Submit
                    </v-btn>
                </v-col>
                <v-col cols="2" align="right">
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    <v-btn v-if="!loading" :color="$store.getters.getColorPalette().cancelbtnColor" :dark="$store.getters.getColorPalette().isDark" @click="close">
                            Cancel
                    </v-btn>
                </v-col>
            </v-row>
        </v-form> -->
    </v-container>
</template>
<script>
import axios from 'axios'
import InfoAlert from '@/components/InfoAlert'
//import EntureStaticLists from '@/utillities/EntureStaticLists'
import FormRules from '@/utillities/FormRules'
import AddTODBillingWidget from '@/components/crud_components/widgets/AddTODBillingWidget.vue'
import AddBarTDWidget from '@/components/crud_components/widgets/AddBarTDWidget.vue'
import AddBarTrendTDWidget from '@/components/crud_components/widgets/AddBarTrendTDWidget.vue'
import AddBarTrendWidget from '@/components/crud_components/widgets/AddBarTrendWidget.vue'
import AddBulletAngularGaugeWidget from '@/components/crud_components/widgets/AddBulletAngularGaugeWidget.vue'
import AddAngularGaugeWidget from '@/components/crud_components/widgets/AddAngularGaugeWidget.vue'
import AddConsumptionGaugeWidget from '@/components/crud_components/widgets/AddConsumptionGaugeWidget.vue'
import AddConsumptionPieWidget from '@/components/crud_components/widgets/AddConsumptionPieWidget.vue'
import AddBusBarLiveMonitoringWidget from '@/components/crud_components/widgets/AddBusBarLiveMonitoringWidget.vue'
import AddGaugeWidget from '@/components/crud_components/widgets/AddGaugeWidget.vue'
import AddHeatMapWidget from '@/components/crud_components/widgets/AddHeatMapWidget.vue'
import AddPieTDWidget from '@/components/crud_components/widgets/AddPieTDWidget.vue'
import AddUsageScoreCardWidget from '@/components/crud_components/widgets/AddUsageScoreCardWidget.vue'
import AddTableWidget from '@/components/crud_components/widgets/AddTableWidget'
import AddBannerWidget from '@/components/crud_components/widgets/AddBannerWidget.vue'
import AddParamEquatedBannerWidget from '@/components/crud_components/widgets/AddParamEquatedBannerWidget.vue'
import AddBitwiseWordWidget from '@/components/crud_components/widgets/AddBitwiseWordWidget.vue'
import AddDynamicLogWidget from '@/components/crud_components/widgets/AddDynamicLogWidget.vue'
import AddProcessStatusBasedWidget from '@/components/crud_components/widgets/AddProcessStatusBasedWidget.vue'
import AddParameterStatusBasedWidget from '@/components/crud_components/widgets/AddParameterStatusBasedWidget.vue'
import AddTargetBasedBarTrendWidget from '@/components/crud_components/widgets/AddTargetBasedBarTrendWidget.vue'
import AddParameterInsightsWidget from '@/components/crud_components/widgets/AddParameterInsightsWidget.vue'
import AddMachineStatusTimelineWidget from '@/components/crud_components/widgets/AddMachineStatusTimelineWidget.vue'
import AddShiftBasedConsumptionTableWidget from '@/components/crud_components/widgets/AddShiftBasedConsumptionTableWidget.vue'
import AddMachineWidget from '@/components/crud_components/widgets/AddMachineWidget.vue'
import AddDualAxisBarTrendWidget from '@/components/crud_components/widgets/AddDualAxisBarTrendWidget.vue'
import AddTriggerCountTableWidget from '@/components/crud_components/widgets/AddTriggerCountTableWidget.vue'
import AddHorizantalBarTrendWidget from '@/components/crud_components/widgets/AddHorizantalBarTrendWidget.vue'
import AddDaywiseMachineStatusWidget from '@/components/crud_components/widgets/AddDaywiseMachineStatusWidget.vue'
import AddConsolidatedDayWiseMachineStatusWidget from '@/components/crud_components/widgets/AddConsolidatedDayWiseMachineStatusWidget.vue'
import AddDaywiseMachineStatusTableWidget from '@/components/crud_components/widgets/AddDaywiseMachineStatusTableWidget.vue'

export default {
    name:'AddWidget',
    props:['view_id','seq'],
    components:{
        InfoAlert,
        AddTODBillingWidget,
        AddBarTDWidget,
        AddBarTrendTDWidget,
        AddBarTrendWidget,
        AddBulletAngularGaugeWidget,
        AddBusBarLiveMonitoringWidget,
        AddGaugeWidget,
        AddHeatMapWidget,
        AddPieTDWidget,
        AddUsageScoreCardWidget,
        AddTableWidget,
        AddBannerWidget,
        AddAngularGaugeWidget,
        AddConsumptionGaugeWidget,
        AddParamEquatedBannerWidget,
        AddBitwiseWordWidget,
        AddDynamicLogWidget,
        AddProcessStatusBasedWidget,
        AddParameterStatusBasedWidget,
        AddTargetBasedBarTrendWidget,
        AddParameterInsightsWidget,
        AddMachineStatusTimelineWidget,
        AddDualAxisBarTrendWidget,
        AddTriggerCountTableWidget,
        AddHorizantalBarTrendWidget,
        AddDaywiseMachineStatusWidget,
        AddConsolidatedDayWiseMachineStatusWidget,
        AddDaywiseMachineStatusTableWidget
    },
    mounted(){
        this.widget=null
        this.form.width='flex'
        this.form.height=12
        this.getTemplates()
        
    },
    data(){
        return {
            valid:false,
            showDismissibleAlert:false,
            loading:false,
            showTemplate:false,
            info:'',
            selected:'',
            template:null,
            templateOptions:[],
            widget_type:null,
            form:{
                meta:[] 
            },
            widget:null,
            showCrud:false,
            compMap:{
                'todBillingWidget':AddTODBillingWidget,
                'barTDWidget':AddBarTDWidget,
                'barTrendTDWidget':AddBarTrendTDWidget,
                'barTrendWidget':AddBarTrendWidget,
                'bulletAngularGaugeWidget':AddBulletAngularGaugeWidget,
                'busBarLiveMonitoring':AddBusBarLiveMonitoringWidget,
                'gaugeWidget':AddGaugeWidget,
                'heatMapWidget':AddHeatMapWidget,
                'pieTDWidget':AddPieTDWidget,
                'usageScoreCardWidget':AddUsageScoreCardWidget,
                'tableWidget':AddTableWidget,
                'bannerWidget':AddBannerWidget,
                'angularGaugeWidget':AddAngularGaugeWidget,
                'consumptionGaugeWidget':AddConsumptionGaugeWidget,
                'consumptionPieWidget':AddConsumptionPieWidget,
                'paramEquatedbannerWidget':AddParamEquatedBannerWidget,
                'bitwiseWordWidget':AddBitwiseWordWidget,
                'dynamicLogWidget':AddDynamicLogWidget,
                'machineStatusTimelineWidget':AddMachineStatusTimelineWidget,
                'processStatusBasedWidget':AddProcessStatusBasedWidget,
                'parameterStatusBasedWidget':AddParameterStatusBasedWidget,
                'targetBasedBarTrendWidget':AddTargetBasedBarTrendWidget,
                'parameterInsightsWidget':AddParameterInsightsWidget,
                'shiftBasedConsumptionTableWidget':AddShiftBasedConsumptionTableWidget,
                'machineWidget':AddMachineWidget,
                'dualAxisBarTrendWidget': AddDualAxisBarTrendWidget,
                'triggerCountTableWidget': AddTriggerCountTableWidget,
                'horizantalBarTrendWidget': AddHorizantalBarTrendWidget,
                'daywiseMachineStatusWidget':AddDaywiseMachineStatusWidget,
                'consolidatedDaywiseMachineStatusWidget':AddConsolidatedDayWiseMachineStatusWidget,
                'daywiseMachineStatusTableWidget':AddDaywiseMachineStatusTableWidget
            },
            objectRules:FormRules.objectRules,
            nameRules: FormRules.nameRules,
            numberRules: FormRules.numberRules,
            nonNegativeRules:FormRules.nonNegativeIntegerRules,
            listRules:FormRules.listRules,
            widthOptions:[
                {label:'Full screen', value:12},
                {label:'Half screen', value:6},
                {label:'1/3 screen', value:4},
                {label:'Quarter screen', value:3},
                {label:'Auto',value:null}
            ],
            heightOptions:[
                {label:'Fixed', value:'fixed'},
                {label:'Flex', value:'flex'},
            ],
            widgetTypeOptions:[
                {label:this.$store.getters.getTextMap().till_date_pie_chart,value:'pieTDWidget',icon:'mdi-chart-pie-outline',condition:this.$store.state.user},
                {label:this.$store.getters.getTextMap().till_date_bar_chart,value:'barTDWidget',icon:'mdi-chart-bar-stacked',condition:this.$store.state.user},
                {label:this.$store.getters.getTextMap().tod_billing_widget,value:'todBillingWidget',icon:'mdi-currency-rupee',condition:this.$store.state.user},
                {label:this.$store.getters.getTextMap().bar_trend_widget,value:'barTrendWidget',icon:'mdi-finance',condition:this.$store.state.user},
                {label:this.$store.getters.getTextMap().target_based_bar_trend_widget,value:'targetBasedBarTrendWidget',icon:'mdi-target',condition:this.$store.state.user},
                {label:this.$store.getters.getTextMap().gauge_widget,value:'gaugeWidget',icon:'mdi-gauge',condition:this.$store.state.user},
                {label:this.$store.getters.getTextMap().consumption_pie_widget,value:'consumptionPieWidget',icon:'mdi-chart-pie-outline',condition:this.$store.state.user},
                {label:this.$store.getters.getTextMap().consumption_gauge_widget,value:'consumptionGaugeWidget',icon:'mdi-gauge',condition:this.$store.state.user},
                {label:this.$store.getters.getTextMap().angular_gauge_widget,value:'angularGaugeWidget',icon:'mdi-chart-ppf',condition:this.$store.state.user},
                {label:this.$store.getters.getTextMap().table_widget,value:'tableWidget',icon:'mdi-table',condition:this.$store.state.user},
                {label:this.$store.getters.getTextMap().banner_widget,value:'bannerWidget',icon:'mdi-card-text-outline',condition:this.$store.state.user},
                {label:this.$store.getters.getTextMap().param_equation_banner_widget,value:'paramEquatedbannerWidget',icon:'mdi-card-bulleted-outline',condition:this.$store.state.user},
                {label:this.$store.getters.getTextMap().machine,value:'machineWidget',icon:'mdi-dharmachakra',condition:this.$store.state.user},
                {label:this.$store.getters.getTextMap().shift_based_usage_table,value:'shiftBasedConsumptionTableWidget',icon:'mdi-table-column',condition:(this.$store.state.user && this.$store.getters.getAccessByLevels(['betaaccess']))},
                {label:this.$store.getters.getTextMap().bitwise_word_widget,value:'bitwiseWordWidget',icon:'mdi-file-word-box-outline',condition:(this.$store.state.user )},
                {label:this.$store.getters.getTextMap().dynamic_log_widget,value:'dynamicLogWidget',icon:'mdi-book-open-page-variant-outline',condition:this.$store.state.user},
                {label:this.$store.getters.getTextMap().machine_status_timeline_widget,value:'machineStatusTimelineWidget',icon:'mdi-chart-timeline',condition:this.$store.state.user},
                {label:this.$store.getters.getTextMap().process_or_machine_status_widget,value:'processStatusBasedWidget',icon:'mdi-list-status',condition:(this.$store.state.user)},
                {label:this.$store.getters.getTextMap().parameter_status_based_usage_status,value:'parameterStatusBasedWidget',icon:'mdi-check-circle-outline',condition:(this.$store.state.user)},
                {label:this.$store.getters.getTextMap().parameter_insights_widget,value:'parameterInsightsWidget',icon:'mdi-table-eye',condition:(this.$store.state.user)},
                {label:this.$store.getters.getTextMap().heat_map,value:'heatMapWidget',icon:'mdi-chart-tree',condition:this.$store.state.user},
                {label:this.$store.getters.getTextMap().usage_score_card_widget,value:'usageScoreCardWidget',icon:'mdi-scoreboard-outline',condition:this.$store.state.user},
                {label:this.$store.getters.getTextMap().busbar_live_monitoring,value:'busBarLiveMonitoring',icon:'mdi-poll',condition:this.$store.state.user},
                {label:this.$store.getters.getTextMap().bullet_or_angular_guage_widget,value:'bulletAngularGaugeWidget',icon:'mdi-chart-ppf',condition:this.$store.state.user},
                { label:this.$store.getters.getTextMap().dual_axis_bar_trend_widget, value: 'dualAxisBarTrendWidget', icon: 'mdi-chart-multiple', condition: (this.$store.state.user ) },
                { label: this.$store.getters.getTextMap().trigger_count_table_widget, value: 'triggerCountTableWidget', icon: 'mdi-alert-outline', condition: (this.$store.state.user ) },
                {label:this.$store.getters.getTextMap().bar_trend_td_widget,value:'barTrendTDWidget',icon:'mdi-chart-line',condition:(this.$store.state.user )},
                {label:this.$store.getters.getTextMap().horizantal_bar_trend_widget,value:'horizantalBarTrendWidget',icon:'mdi-chart-sankey',condition:(this.$store.state.user)},
                {label:this.$store.getters.getTextMap().day_wise_machine_status_widget,value:'daywiseMachineStatusWidget',icon:'mdi-chart-histogram',condition:(this.$store.state.user)},
                {label:this.$store.getters.getTextMap().consolidated_day_wise_machine_status_widget,value:'consolidatedDaywiseMachineStatusWidget',icon:'mdi-chart-bar',condition:(this.$store.state.user)},
                {label:this.$store.getters.getTextMap().day_wise_machine_status_table_widget,value:'daywiseMachineStatusTableWidget',icon:'mdi-periodic-table',condition:(this.$store.state.user)},
            ]
        }
    },
    methods:{
        emitSuccess(){
            this.widget=null
            this.form={}
            this.form.meta=[]
            this.$emit('success')
        },
        handleItemClick(item){
            this.form.widget_type = item.value
            // if(this.form.width && this.form.height && this.form.label){
                this.widget=this.form
                this.widget['view_id']=this.view_id
                this.widget_type = item.value
                this.showCrud=true
            // }else{
            //     this.info = 'Please fill widget details';
            //     this.showDismissibleAlert = true;
            //     this.loading=false
            // }
            // if(this.widget && this.widget.widget_id && this.widget.view_id && this.form.label){
            //     this.deleteWidget()
            // }
            // this.onSubmit()
        },
        getWidgetData(){

        },
        getWidget(){
            return this.compMap[this.widget_type]
        },
        getTemplates(){
            axios.get(this.$store.state.api+'getTemplates',{headers: {
              Authorization: 'Bearer '+ this.$store.state.jwt
            }}).then(response=>{
                if(response.data.status==='success'){
                    for(let i of response.data.template){
                        this.templateOptions.push({value:i.template_id,label:i.name})
                    }
                  //this.$store.dispatch('refreshComboParams')
              }else{
                  this.info = response.data.msg;
          
                  this.showDismissibleAlert = true;
              
              this.loading=false
                
              }
            }).catch(err=>{
                  this.info = err;
                    this.showDismissibleAlert = true;
              this.loading=false
              })
        },
        showTemplates(){
            this.showTemplate = ! this.showTemplate
    },
    deleteWidget(){
            this.loading=true
            axios.post(this.$store.state.api+'deleteWidget',this.widget,{headers: {
                Authorization: 'Bearer '+ this.$store.state.jwt
                }}).then(response=>{
                if(response.data.status==='success'){
                    
                    //this.onReset()
                    this.dialog=false
                        this.loading=false
                        //this.dialog=false
                        this.$emit('update')
                }
                })
                .catch(err=>{
                    console.error(err)
                this.loading=false});
        },

        onSubmit(){
            this.widget={}
            if(this.showTemplate===false){
                this.$refs.paramForm.validate()
                if(this.valid){
                  this.loading=true
                //evt.preventDefault()
                //this.form.id=(new Date).getTime().toString()
                this.form.param_type=this.param_type
                let d=Object.assign({}, this.form)
                d['view_id']=this.view_id
                d['seq']=this.seq
                d['meta']=[]
                axios.post(this.$store.state.api+'createWidgetForView',d,{headers: {
          Authorization: 'Bearer '+ this.$store.state.jwt
        }})
        .then(response=>{
          if(response.data.status==='success'){
              //this.$store.dispatch('refreshComboParams')
              this.loading=false
              this.showTemplate=false
              this.widget=response.data.widget
              this.widget.meta=null
              this.showCrud=true
            //   this.$emit('success')
            //   this.close()
          }else{
              this.info = response.data.msg;
      
              this.showDismissibleAlert = true;
          
          this.loading=false
          }}).catch(err=>{
              this.info = err;
                this.showDismissibleAlert = true;
          this.loading=false
          })
                }
            }else{
                this.$refs.paramForm.validate()
                if(this.valid){
                  this.loading=true
                //evt.preventDefault()
                //this.form.id=(new Date).getTime().toString()
                this.form.param_type=this.param_type
                console.log(this.view_id,'this.form widget')
                let d=Object.assign({})
                d['view_id']=this.view_id
                d['seq']=this.seq
                d['template_id']=this.template
                axios.post(this.$store.state.api+'createTemplateWidgetForView',d,{headers: {
          Authorization: 'Bearer '+ this.$store.state.jwt
        }})
        .then(response=>{
          if(response.data.status==='success'){
              //this.$store.dispatch('refreshComboParams')
              this.seq=response.data.seq
              this.loading=false
            //   this.widget=response.data.widget
            //   this.showCrud=true
              this.$emit('success')
              this.close()
          }else{
              this.info = response.data.msg;
      
              this.showDismissibleAlert = true;
          
          this.loading=false
          }}).catch(err=>{
              this.info = err;
                this.showDismissibleAlert = true;
          this.loading=false
          })
                } 
            }
        },
        close(){
            this.$refs.paramForm.reset()
            this.dialog=false
            this.loading=false
            this.showTemplate=false
            this.$emit('close')
        }
    },
    watch:{
        selected(){
            this.showTemplates()
        }
    }
}
</script>
<style scoped>

.widget-card-container {
height: 560px;
  max-height: 560px;
  overflow-y: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
}
.widgetype-card-container {
/* height: 380px; */
  max-height: 450px;
  overflow-y: auto;
  /* scrollbar-width: 1px;
  -ms-overflow-style: none; */
}
</style>