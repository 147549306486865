<template>
  <v-card
    :style="{
      'border-radius': '5px',
      border:
        '1px solid' + $store.getters.getColorPalette().panelBorderColorCode,
    }"
    flat
    :dark="$store.getters.getColorPalette().isDark"
    :color="$store.getters.getColorPalette().background2ColorCode"
    
  >
  <div v-if="showMachineStatus" :style="{ 'background-color': statusCardColor }">
            <getMachineStatus :machine="machine" v-on:success="showMachineStatus=true" v-on:cancel="showMachineStatus=false" v-on:color="changeCardColor"/>
    </div>
    <v-container fluid>
      <div style="cursor: pointer" fluid @click="dialog = !dialog">
        <v-row no-gutters>
          <v-col 
            >&nbsp;<span
              :style="{ color: $store.getters.getColorPalette().textColor }"
              ><strong>{{ machine.name }}</strong></span
            ></v-col
          >
        </v-row>
        <v-divider
          :color="$store.getters.getColorPalette().accentCode"
        ></v-divider>
        <!-- <v-card class="mx-1" v-if="showMachineStatus" flat :dark="$store.getters.getColorPalette().isDark" :color="statusCardColor"> -->
        <!-- <v-card v-if="showMachineStatus" flat :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode"> -->

        <!-- </v-card> -->
        <v-row no-gutters v-for="param in featuredParams" :key="param.param_id">
          <v-col
            >&nbsp;<span
              :color="$store.getters.getColorPalette().background2ColorCode"
              >{{ param.name }}&nbsp;:<strong>
                &nbsp; &nbsp;{{ streamData[param.param_id] }}</strong
              ></span
            ></v-col
          >
        </v-row>
      </div>

      <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-card
          :dark="$store.getters.getColorPalette().isDark"
          :color="$store.getters.getColorPalette().backgroundColorCode"
        >
          <v-toolbar
            :dark="$store.getters.getColorPalette().isDark"
            :color="$store.getters.getColorPalette().foregroundColorCode"
          >
            <v-btn
              icon
              :dark="$store.getters.getColorPalette().isDark"
              @click="dialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title :dark="$store.getters.getColorPalette().isDark"
              >{{
                machine.name ? machine.name : machine.machine_id
              }}
              {{ $store.getters.getTextMap().details}}</v-toolbar-title
            >
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn
                :dark="$store.getters.getColorPalette().isDark"
                text
                @click="dialog = false"
              >
               {{ $store.getters.getTextMap().close}}
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-row no-gutters>
            <v-col><slot name="top"></slot></v-col>
          </v-row>

          <MachineDetails
            :hideDelete="hideDelete"
            :machine="machine"
            v-on:paramUpdate="getFavParams"
            v-on:machineUpdate="$emit('machineUpdate')"
          />
        </v-card>
      </v-dialog>
    </v-container>
  </v-card>
</template>
<script>
import MachineDetails from "@/components/panels/MachineDetails";
import getMachineStatus from "@/components/getMachineStatus"
//import ColorUtility from '@/utillities/ColorUtility'
import { io } from "socket.io-client";
import axios from "axios";
export default {
  name: "Machine",
  props: ["machine", "hideDelete"],
  components: {
    MachineDetails,
    getMachineStatus
  },
  created() {
    //this.socket=io('https://localhost:80');
    this.stream = io(this.$store.state.streamApi, {
      withCredentials: true,
      auth: { token: this.$store.state.jwt },
    });
  },
  beforeDestroy() {
    //this.socket.close()
    //console.log('before destroy')
    this.stream.close();
  },
  mounted() {
    this.stream.on("connect_error", (err) => {
      console.log(err.message); // prints the message associated with the error
    });
    this.stream.on("error", (data) => {
      console.error(data);
    });

    this.stream.on("param", (data) => {
      if (data.constructor == String) {
        data = JSON.parse(data);
      }
      let temp = Object.assign({}, this.streamData);
      if(!('param_id' in data)){
        data.param_id=data.parameter
      }
      temp[data.param_id] = Math.round(Number(data.data) * 1000) / 1000;
      this.streamData = temp;
    });
    this.getFavParams();
    //this.configureParamStream()
  },
  data() {
    return {
      //colorUtil:ColorUtility,
      dialog: false,
      loading: false,
      info: "",
      showDismissibleAlert: false,
      cache: {},
      streamData: {},
      featuredParams: [],
      showMachineStatus:true,
      statusCardColor:this.$store.getters.getColorPalette().background2ColorCode
    };
  },

  methods: {
    changeCardColor(value){
      if(value){
        this.statusCardColor=value
      }
    },
    configureParamStream() {
      if (this.streamData) {
        for (let i of Object.keys(this.streamData)) {
          this.stream.emit("removeParam", i);
        }
      }
      if (this.featuredParams && this.featuredParams.length > 0) {
        for (let s of this.featuredParams) {
          this.stream.emit("getParam", s.param_id);
          //console.log(s.param_id)
        }
      }
    },
    getFavParams() {
      if (this.machine && this.machine.machine_id) {
        axios
          .post(
            this.$store.state.api + "getParamsByMachine",
            { machine_id: this.machine.machine_id, featured: true },
            {
              headers: {
                Authorization: "Bearer " + this.$store.state.jwt,
              },
            }
          )
          .then((response) => {
            if (response.data.status == "success") {
              this.loading = false;
              this.featuredParams = response.data.params
              // console.log(this.featuredParams)
              this.configureParamStream();
            } else {
              this.loading = false;
              this.info = response.data.msg;
              this.showDismissibleAlert = true;
            }
          })
          .catch((err) => {
            this.loading = false;
            this.info = err;
            this.showDismissibleAlert = true;
          });
      }
    },
  },
};
</script>
<style scoped>
.v-card {
  margin-top: 2%;
  margin-left: -2%;
  font-size: 1rem;
  font-weight: 400;
}

.shadow-card {
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}
</style>
