<template>
    <v-container fluid>
        
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" :info="info" v-on:close="showDismissibleAlert=false" />
<v-row>
    <v-col>
          
          <v-form
        
    ref="parameterForm"
    v-model="valid" >
      <v-card :color="$store.getters.getColorPalette(). backgroundColorCode" :dark="$store.getters.getColorPalette().isDark">
        <v-card-title class="headline">
          {{$store.getters.getTextMap().add_machine_param}}
        </v-card-title>
        <v-card-text>
            <v-container fluid>
                <v-row>
                    <v-col>
                      <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
            <v-text-field
          :dark="$store.getters.getColorPalette().isDark"
           :color="$store.getters.getColorPalette().inputtextColor"
            v-if="!loading"
      v-model="form.name"
      :counter="32"
      :rules="nameRules"
      :label="$store.getters.getTextMap().name"
      required
    ></v-text-field>
                    </v-col>
 <v-col >
   <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
                        <v-text-field
 :dark="$store.getters.getColorPalette().isDark"
                      :color="$store.getters.getColorPalette().inputtextColor"
                        v-if="!loading"
                        v-model="form.unit"
                        :counter="5"
                        
                        :label="$store.getters.getTextMap().unit"
                        required>

                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                  <v-col v-if="form.command!=1">
                    <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
                        <v-select
                       :dark="$store.getters.getColorPalette().isDark"
                        :color="$store.getters.getColorPalette().inputtextColor"
                        v-if="!loading"
          v-model="form.processing"
          :items="processing"
          item-text="label"
          item-value="value"
          :label="$store.getters.getTextMap().data_processing"
         :rules="selectRules"
         required
          
          clearable
     :menu-props="{dark: $store.getters.getColorPalette().isDark}"
        ></v-select>
                    </v-col>
                  <v-col>
                    <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
                        <v-select
                        :dark="$store.getters.getColorPalette().isDark"
                         :color="$store.getters.getColorPalette().inputtextColor"
                        v-if="!loading"
          v-model="form.data_type"
          :items="dataTypes"
          item-text="label"
          item-value="value"
          :label="$store.getters.getTextMap().data_type"
         :rules="selectRules"
         required
          
          clearable
          :menu-props="{dark: $store.getters.getColorPalette().isDark}"
        ></v-select>
                    </v-col>
                </v-row>
    </v-container>
        </v-card-text>
        <v-card-actions>
          <v-checkbox
         :dark="$store.getters.getColorPalette().isDark"
           :color="$store.getters.getColorPalette().inputtextColor"
          v-if="!loading"
      v-model="form.engineerAccessOnly"
      :label="$store.getters.getTextMap().restricted_user_access"
    ></v-checkbox>
          <v-checkbox
         :dark="$store.getters.getColorPalette().isDark"
           :color="$store.getters.getColorPalette().inputtextColor"
          v-if="!loading"
      v-model="form.aliasing"
      :label="$store.getters.getTextMap().aliasing"
    ></v-checkbox>
          <v-checkbox
        :dark="$store.getters.getColorPalette().isDark"
          :color="$store.getters.getColorPalette().inputtextColor"
          v-if="!loading"
      v-model="form.rem_zero"
      :label="$store.getters.getTextMap().no_zero_read"
    ></v-checkbox>
          <v-checkbox
        :dark="$store.getters.getColorPalette().isDark"
          :color="$store.getters.getColorPalette().inputtextColor"
          v-if="!loading"
      v-model="form.rem_negative_entropy"
      :label="$store.getters.getTextMap().remove_negative_entropy"
    ></v-checkbox>
          <v-checkbox
        :dark="$store.getters.getColorPalette().isDark"
          :color="$store.getters.getColorPalette().inputtextColor"
          v-if="!loading"
      v-model="form.calculate_consumption"
      :label="$store.getters.getTextMap().calculate_consumption"
    ></v-checkbox>
          <v-checkbox
        :dark="$store.getters.getColorPalette().isDark"
          :color="$store.getters.getColorPalette().inputtextColor"
          v-if="!loading"
      v-model="form.disabled"
      :label="$store.getters.getTextMap().disabled"
    ></v-checkbox>
          <!-- <v-checkbox
        :dark="$store.getters.getColorPalette().isDark"
          :color="$store.getters.getColorPalette().inputtextColor"
          v-if="!loading"
      v-model="form.entropy_limiter"
      :label="`Limit Entropy`"
    ></v-checkbox> -->
    <v-text-field
      :dark="$store.getters.getColorPalette().isDark"
      :color="$store.getters.getColorPalette().inputtextColor"
      v-if="!loading && form.entropy_limiter"
      v-model="form.entropy_lim_val"
      :counter="5"
      :rules="numberRules"
      :label="$store.getters.getTextMap().limiter_value"
      required
    ></v-text-field>
          <v-spacer></v-spacer>
          <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
    
          <v-btn
          v-if="!loading"
           :color="$store.getters.getColorPalette().submitbtnColor"
            text
            @click="onSubmit"
          >
          {{  $store.getters.getTextMap().create}}
          </v-btn>
          <v-btn
           :color="$store.getters.getColorPalette().submitbtnColor"
            text
            @click="onReset"
          >
          {{  $store.getters.getTextMap().cancel}}
          </v-btn>
        </v-card-actions>
      </v-card>
      </v-form>
        </v-col>
        </v-row>
        </v-container>
</template>

<script>
import axios from 'axios'
import InfoAlert from '@/components/InfoAlert'

export default {
    name:'CreateMachineParam',
    props:['machine'],
    components:{
        InfoAlert
    },
     data(){
        return {
            dialog:false,
            valid:false,
            loading:false,
            info:'',
            showDismissibleAlert:false,
            // byteFormat:['bigEndian','littleEndian',"midLittle",'midBig'],
            
            processing:[
              {label:'Average',value:'mean'},
                {label:'Difference',value:'difference'},
                {label:'Negative Difference',value:'negative_difference'},
            ],
            
            valTypes:[
              {label:'Unsigned Integer',value:'uint'},
              {label:'Signed Integer',value:'int'},
              {label:'float',value:'float'}
            ],
            nameRules: [
        v => !!v || 'Name is required',
        v => /\S+/.test(v) || 'Name is required',
        v => (v && v.length <= 32 && v.length > 0) || 'Name must be less than 33 characters',
      ],
      unitRules:[
          v => !!v || 'Unit is required',
        v => /\S+/.test(v) || 'Unit is required',
        v => (v && v.length <= 5) || 'Unit length is invalid'
      ],
      numberRules:[
          v => !!v || 'Required',
          v => /[0-9]+$/.test(v) || 'It should be a number',
          v => (v && v>0 && v%1==0)|| 'It should be a valid number',
          v => (v && v.length <= 5 && v.length > 0) || 'Name must be less than 5 digits',
      ],
      floatRules:[
          v => !!v || 'Required',
          v => (v && !isNaN(Number(v))) || 'It should be a number',
          v => (v && v>0 )|| 'It should be more than zero',
          v => (v && v.length <= 12 && v.length > 0) || 'It must be less than 12 digits',
      ],
        msgRules: [
        v => !!v || 'Description is required',
        v => (v && v.length <= 35 && v.length > 0) || 'Description must be less than 35 characters',
      ],
      selectRules:[
        v=> (v!=null) || 'Required'
      ],
      form:{
                name:null,
                unit:null,
                
                processing:null,
                engineerAccessOnly:false,
                aliasing:false,
                val_type:'uint',
                rem_zero:false
                
            }
        }
    },
    computed:{
        dataTypes(){
        let op=[]
        if(this.$store.state.dataTypes){
          for(let i of this.$store.state.dataTypes){
            op.push({
              label:i.type,
              value:i.id
            })
          }
        }
        return op
      }
      
    },
    methods:{
        onSubmit(){
            this.$refs.parameterForm.validate()
            if(this.valid){
              this.loading=true
            //evt.preventDefault()
            //this.form.id=(new Date).getTime().toString()
            let d=Object.assign({}, this.form)
            d.machine_id=this.machine.machine_id
            axios.post(this.$store.state.api+'CreateMachineParam',d,{headers: {
      Authorization: 'Bearer '+ this.$store.state.jwt
    }})
    .then(response=>{
      if(response.data.status==='success'){
        //this.$store.commit('addParameter',d)
        // this.$store.dispatch('refreshParameters')
        //console.log("Parameter added "+d)
        this.$emit('update')
        this.$emit('close')
        // this.onReset()
            //this.dialog=false
            //this.$emit('close')
    }else{
      //console.log(response.data.msg)
      this.info = response.data.msg;
      //this.info = d;
            this.showDismissibleAlert = true;
      //console.log(response.data.msg)
      this.loading=false
    }})
    .catch(err=>{console.log(err) 
        this.loading=false});
                //this.$store.dispatch('addDevice', d)
            
            }
        },
        onReset(){
            //evt.preventDefault()
            /*this.form={
                id:null,
                msg:null,
                name:null
            }*/
            this.$refs.parameterForm.reset()
            this.dialog=false
            this.loading=false
            this.$emit('close')
        }

    }

}
</script>
<style scoped>
/* .v-application .headline{
  color:white;
}
.v-sheet.v-card {
   background-color: #263238;
} */
</style>


