<template>
    <v-container color="transparent">
            

    <v-dialog
      v-model="showDismissibleAlert"
      max-width="290"
    >
      <v-card>
        <v-card-title class="headline">{{$store.getters.getTextMap().oops}}</v-card-title>

        <v-card-text>
         {{info}}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          

          <v-btn
           color="#26A69A"
            text
            @click="showDismissibleAlert = false"
          >
          <!-- <v-btn
           :color="$store.getters.getColorPalette().submitbtnColor"
            text
            @click="showDismissibleAlert = false"
          > -->
          {{$store.getters.getTextMap().ok}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>      
    <v-card>      
                <v-tabs
      v-model="tab"
     :dark="true" 
      background-color="#37474F"
      grow
      
    >
                <!-- <v-tabs
      v-model="tab"
     :dark="$store.getters.getColorPalette().isDark" 
      :background-color="$store.getters.getColorPalette().background2ColorCode"
      grow
      
    > -->
      <v-tab >
        {{$store.getters.getTextMap().root_login}}
      </v-tab>
      <v-tab >
        {{$store.getters.getTextMap().uam_login}}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab" color="transparent" >

        <v-tab-item >
        <v-card  color="grey lighten-3" flat>
        <!-- <v-card  :color="$store.getters.getColorPalette().cardColorLoginPage" flat> -->
    
  <v-form ref="form" v-model="valid">
    <v-container color="transparent">
        
      <v-row align="center" justify="center">
        <v-col
          cols="10"
          
        >
        <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      
      type="list-item-three-line"
    ></v-skeleton-loader>
          <v-text-field
          prepend-icon="mdi-email"
            v-model="form.email"
            :rules="emailRules"
            :label="$store.getters.getTextMap().email"
            required
            v-if="!loading"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row align="center" justify="center">
        
        <v-col cols="10" >
            <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="list-item-three-line"
    ></v-skeleton-loader>
          <v-text-field
            v-model="form.password"
            :rules="[rules.required]"
            :label="$store.getters.getTextMap().password"
            prepend-icon="mdi-key"
            :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPass ? $store.getters.getTextMap().text : $store.getters.getTextMap().password"
            @click:append="showPass = !showPass"
            required
            v-if="!loading"
          ></v-text-field>
        </v-col>
      </v-row>
      
      <v-row align="center" justify="space-around">
          <v-col class ="text-center" cols="6" md="6" xl="6" xs="12" sm="12" >
              <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
              <v-btn v-if="!loading" :dark="true" class="px-10"  @click="login" >{{$store.getters.getTextMap().login}}</v-btn>
              </v-col>
              <v-progress-circular
      indeterminate
      v-if="loading"
      color="primary"
    ></v-progress-circular>
          <v-col class ="text-center" cols="6" md="6" xl="6" xs="12" sm="12" >
              <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      max-width="300"
      type="text"
    ></v-skeleton-loader>
              <v-btn  v-if="!loading" class="px-10"  @click="onReset" >{{$store.getters.getTextMap().reset}}</v-btn>
          </v-col>
      </v-row>
      <v-row v-if="!loading">
        <v-col align="center" ><v-btn @click="dialog=true" text x-small color="error">{{$store.getters.getTextMap().forget_password}}</v-btn></v-col>
      </v-row>
    </v-container>
  </v-form>

        </v-card>
      </v-tab-item>
        <v-tab-item color="transparent" >
        <v-card  color="grey lighten-3" flat>
          <v-form ref="uam_form" v-model="valid_uam">
    <v-container>
        
      <v-row align="center" justify="center">
        
        <v-col
          cols="8"
          
        >
        <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      
      type="list-item-three-line"
    ></v-skeleton-loader>
          <v-text-field
          prepend-icon="mdi-city"
            v-model="UAMform.organisation_id"
            :rules="[rules.required]"
            :label="$store.getters.getTextMap().organisation_id"
            required
            v-if="!loading && !alias"
          ></v-text-field>
          <v-text-field
          prepend-icon="mdi-city"
            v-model="UAMform.alias"
            :rules="[rules.required]"
            :label="$store.getters.getTextMap().organisation_alias"
            required
            v-if="!loading && alias"
          ></v-text-field>
        </v-col>
<v-col align-self="center" align="right" cols="2"><v-checkbox
              v-model="alias"
              :label="$store.getters.getTextMap().org_alias"
              color="orange"
              hide-details
            ></v-checkbox></v-col>
      </v-row>
      <v-row align="center" justify="center">
        
        <v-col
          cols="10"
          
        >
        <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      
      type="list-item-three-line"
    ></v-skeleton-loader>
          <v-text-field
          prepend-icon="mdi-account-box"
            v-model="UAMform.userID"
            :rules="[rules.required]"
            :label="$store.getters.getTextMap().user_id"
            required
            v-if="!loading"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row align="center" justify="center">
        
        <v-col cols="10" >
            <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="list-item-three-line"
    ></v-skeleton-loader>
          <v-text-field
            v-model="UAMform.password"
            :rules="[rules.required]"
            :label="$store.getters.getTextMap().password"
            prepend-icon="mdi-key"
            :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPass ? $store.getters.getTextMap().text: $store.getters.getTextMap().password"
            @click:append="showPass = !showPass"
            required
            v-if="!loading"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row align="center" justify="space-around">
          <v-col class ="text-center" cols="6" md="6" xl="6" xs="12" sm="12" >
              <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
              <v-btn v-if="!loading " :dark="true" class="px-10"  @click="uamLogin" >{{$store.getters.getTextMap().login}}</v-btn>
              </v-col>
              <v-progress-circular
      indeterminate
      v-if="loading"
      color="primary"
    ></v-progress-circular>
          <v-col  class ="text-center" cols="6" md="6" xl="6" xs="12" sm="12" >
              <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      max-width="300"
      type="text"
    ></v-skeleton-loader>
              <v-btn  v-if="!loading" class="px-10"  @click="onReset" >{{$store.getters.getTextMap().reset}}</v-btn>
          </v-col>
      </v-row>
    </v-container>
  </v-form>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
                
        <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
    <v-card>
    <v-toolbar
        :dark="true"
          color="primary"
        >
          <v-btn
            icon
           :dark="true"
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{$store.getters.getTextMap().reset_password}}
</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
            :dark="true"
              text
              @click="dialog = false"
            >
            {{$store.getters.getTextMap().close}}

            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <ResetPasswordComponent v-on:close="dialog=false"/>
    </v-card>
        </v-dialog>
        </v-card>
    </v-container>
</template>

<script>
import ResetPasswordComponent from '@/components/ResetPasswordComponent'
import axios from "axios";
axios.defaults.withCredentials = false;
export default {
    name:'LoginComponent',
    components:{
      ResetPasswordComponent
    },
    mounted() {
         if (this.$store.state.loggedIn) {
      this.$router.push("/dashboard");
    }else{
      if(localStorage.getItem('org_id')){
        this.UAMform['organisation_id']=localStorage.getItem('org_id')
      }
      if(localStorage.getItem('org_alias')){
        this.UAMform['alias']=localStorage.getItem('org_alias')
        this.alias=true
      }
    }
    },
    data() {
        return {
            tab:0,
            alias:false,
            dialog:false,
            valid: false,
            valid_uam: false,
            showPass:false,
            showDismissibleAlert: false,
      loading: false,
            emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      rules: {
          required: value => !!value || 'Required.',
          min: v => v.length >= 8 || 'Min 8 characters',
          emailMatch: () => ('The email and password you entered don\'t match'),
        },
      info: null,
      form: {
        email: "",
        password: ""
      },
      UAMform: {
        userID: "",
        password: "",
        //organisation_id: ""
      },
      
        }
    },
    computed:{
      loggedIn(){
        return this.$store.state.loggedIn
      }
    },
    methods:{
        login() {
      this.$refs.form.validate()
      if(this.valid){
         // console.log("something")
      this.loading = true;
      axios
        .post(this.$store.state.api + "login", this.form)
        .then(response => {
          if (response.data.status == "success") {
            
            this.$store.commit("setUser", response.data.user);
            this.$store.commit("setJWT", response.data.jwt);
            this.$store.commit("setLoggedIn", true)
            //console.log(response.data.jwt);
            localStorage.setItem('enture_token',response.data.jwt)
            this.$store.dispatch("refresh");
            
            setTimeout(()=>{this.$store.dispatch("refresh")}, 30000);
            //console.log("redirecting to dashboard")
            this.onReset()
            this.$router.push("/dashboard");
          } else {
            this.info = response.data.error;
            this.showDismissibleAlert = true;
          }
          
          this.loading = false;
        })
        .catch(error => {
          this.form = {
            email: "",
            password: ""
          };
          this.info = error;
          this.showDismissibleAlert = true;
          this.loading = false;
        });
    
        }
        },
        onReset () {
        this.$refs.form.reset()
        this.$refs.uam_form.reset()
      },
      uamLogin() {
          this.$refs.uam_form.validate()
          if(this.alias){
        delete this.UAMform['organisation_id']
      }else{
        delete this.UAMform['alias']
      }
          if(this.valid_uam){
      
      this.loading = true;
      
      axios
        .post(this.$store.state.api + "UAMlogin", this.UAMform)
        .then(response => {
          if (response.data.status == "success") {
            //console.log(response.data)
            this.onReset()
            if(!this.alias && this.UAMform['organisation_id']){
            localStorage.setItem('org_id',this.UAMform['organisation_id'])
            localStorage.removeItem('org_alias')
            }else{
              localStorage.removeItem('org_id')
              localStorage.setItem('org_alias',this.UAMform['alias'])
            }
            this.$store.commit("setJWT", response.data.jwt);
            this.$store.commit("setUser", response.data.user);
            this.$store.commit("setLoggedIn", true)
            localStorage.setItem('enture_token',response.data.jwt)
            this.$store.dispatch("refresh");
            this.$router.push("/dashboard");
            this.UAMform = {};
          } else {
            this.info = response.data.error;
            this.showDismissibleAlert = true;
          }
          
          this.loading = false;
        })
        .catch(error => {
          console.log(error)
          this.form = {
            userID: "",
            password: "",
            organisation_id: ""
          };
          this.info = error;
          this.showDismissibleAlert = true;
          this.loading = false;
        });
    
      }
      },
    },
    watch:{
      loggedIn:function (v){
        if(v){
          this.$router.push("/dashboard");
        }
      }
    }
}
</script>